<template>
  <FinalField :name="name" :validate="validate">
    <template v-slot="props">
      <div class="form-col">
        <label :for="name">{{label}}</label>
        <DatePicker
          :name="name"
          :value="prepareRangeValues(props.value)"
          @apply="(v) => props.change(formatDates(v))"
          @clear="() => props.change(null)"
          :class="{error: props.meta.error && props.meta.touched}"
          :clearable="clear"
          :disabled="disabled"
          :min-date="minDate"
          :max-date="maxDate"
          :placeholder="placeholder"
          :position="position"
          :format="format"
          range
          multiCalendars
        >
          <template v-slot:input>
            <slot name="input" v-bind="getDates(props.value)"></slot>
          </template>
        </DatePicker>
        <span class="form-hint form-error" v-if="props.meta.error && props.meta.touched">{{ props.meta.error }}</span>
      </div>
    </template>
  </FinalField>
</template>

<script>
  import {FinalField} from 'vue-final-form';
  import moment from 'moment';
  import DatePicker from '@/components/ui/datepicker/DatePicker';

  export default {
    name: 'DateRangeInput',
    components: {
      FinalField,
      DatePicker,
    },
    props: {
      name: {
        type: String,
        required: true,
      },
      label: {
        type: String,
      },
      minDate: {
        type: String,
        default: null,
      },
      maxDate: {
        type: String,
        default: null,
      },
      placeholder: {
        type: String,
        default: 'select date range',
      },
      position: {
        type: String,
      },
      validate: {
        type: [Function, Array],
        required: false,
      },
      clear: {
        type: Boolean,
        default: false
      },
    },
    methods: {
      prepareRangeValues(value) {
        const { startDate = null, endDate = null } = value || {};
        return [startDate, endDate];
      },
      formatDates(value) {
        const [startDate, endDate] = value || [];
        return {
          startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
          endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
        };
      },
      getDates(value) {
        return value || this.formatDates();

      },
    }
  };
</script>

<style scoped>

</style>

