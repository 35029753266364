<template>
  <span>{{ formatDateTime(input, format, keepLocalTime) }}</span>
</template>

<script>
import CommunityTimezoneMixin from "@/mixins/CommunityTimezoneMixin";

export default {
  name: "CommunityDateTimeDisplay",

  mixins: [CommunityTimezoneMixin],

  props: {
    input: {
      required: true
    },

    format: {
      type: String
    },

    keepLocalTime: {
      type: Boolean,
      default: false,
    },
  }
}
</script>

<style scoped>

</style>
