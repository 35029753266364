<template>
  <FinalField :name="name" :validate="validate">
    <template v-slot="props">
      <div class="form-col">
      <label v-if="label" :for="name">{{label}}</label>
      <div v-if="!editMode">
        <slot name="option" v-bind="{option: getSelectedOption(props.value)}">
          {{getSelectedValue(props.value)}}
        </slot>
      </div>
      <Dropdown
        v-bind="$attrs"
        v-if="editMode"
        ref="select"
        :data-test="name"
        :options="options"
        :data-provider="dataProvider"
        :resource="resource"
        :request-params="requestParams"
        :response-mapper="responseMapper"
        :item-mapper="itemMapper"
        :lazy-load="lazyLoad"
        :page-size="pageSize"
        :multiple="multiple"
        :object-mode="objectMode"
        :trackBy="optionKey"
        :label="optionLabel"
        :placeholder="placeholder"
        :searchable="searchable"
        :local-filtering-function="localFilteringFunction"
        :no-clear="noClear"
        :disabled="disabled"
        :preselect-first-option="preselectFirstOption"
        :value="props.value"
        @input="value => props.change(value)"
        @optionsLoaded="val => $emit('options-loaded', val)"
      >
        <template v-slot:tags="params">
            <slot name="tags" v-bind="params"/>
          </template>
          <template v-slot:option="params">
            <slot name="option" v-bind="params"/>
          </template>
          <template v-slot:option-content="params">
            <slot name="option-content" v-bind="params"/>
          </template>
          <template v-slot:empty-result>
            <slot name="empty-result" />
          </template>
        </Dropdown>
        <span class="form-error" v-if="!suppressError && props.meta.error && props.meta.touched">{{ props.meta.error }}</span>
        <span class="form-hint" v-else-if="hint">{{ hint }}</span>
      </div>
    </template>
  </FinalField>
</template>

<script>
  import {FinalField} from 'vue-final-form';
  import Dropdown from "@/components/ui/Dropdown";

  export default {
    name: 'SelectInput',

    components: {
      Dropdown,
      FinalField,
    },

    props: {
      /**
       * The name of the field in the form
       */
      name: {
        type: String,
        required: true,
      },

      /**
       * The label text for the field
       */
      label: {
        type: String,
      },

      /**
       * The hint text for the field
       */
      hint: {
        type: String,
        required: false,
      },

      /**
       * Array of available options
       */
      options: {
        type: Array,
        required: false,
      },

      /**
       * Data provider to fetch options
       */
      dataProvider: {
        type: Object,
        required: false,
      },

      /**
       * Name of a resource used by the data provider
       */
      resource: {
        type: String,
        required: false,
      },

      /**
       * Additional parameters that could be sent with options requests
       */
      requestParams: {
        type: Object,
        required: false,
      },

      /**
       * Data mapping function applied to each response
       */
      responseMapper: {
        type: Function,
        required: false,
      },

      /**
       * Data mapping function applied to each item from a response
       */
      itemMapper: {
        type: Function,
        required: false,
      },

      /**
       * Should options be loaded partially while scrolling the options list
       */
      lazyLoad: {
        type: Boolean,
        required: false,
      },

      /**
       * Number of options that could partially loaded in the lazy mode
       */
      pageSize: {
        type: Number,
        required: false,
      },

      /**
       * Equivalent to the `multiple` attribute on a `<select>` input
       */
      multiple: {
        type: Boolean,
        default: false,
      },

      /**
       * Should the value be option object or just key
       */
      objectMode: {
        type: Boolean,
        default: false,
      },

      /**
       * Key to find options
       */
      optionKey: {
        type: String,
        default: 'key',
      },

      /**
       * Label to look for in option object
       */
      optionLabel: {
        type: String,
        default: 'value'
      },

      /**
       * A field-level validation function or an array of functions
       */
      validate: {
        type: [Function, Array],
        required: false,
      },

      /**
       * Equivalent to the `placeholder` attribute on a `<select>` input
       */
      placeholder: {
        type: String,
        required: false,
      },

      /**
       * Enable/disable search in options
       */
      searchable: {
        type: Boolean,
        required: false,
        default: true,
      },

      /**
       * Custom options filtering function.
       * Receives 2 parameters - search query and unfiltered options array. Must return array of filtered options
       */
      localFilteringFunction: {
        type: Function,
        required: false,
      },

      /**
       * Switches off the Clear button
       */
      noClear: {
        type: Boolean,
        required: false,
      },

      /**
       * If the control should be active, or just be presented as a text value
       */
      editMode: {
        type: Boolean,
        default: true,
      },

      /**
       * If the control should be disabled
       */
      disabled: {
        type: Boolean,
        required: false,
      },
      /**
       * Hide error message
       */
      suppressError: {
        type: Boolean,
        required: false,
        default: false
      },

      /**
       * Select first option by default
       */
      preselectFirstOption: {
        type: Boolean,
        default: false,
      },
    },

    emits: ['options-loaded'],

    methods: {
      getSelectedValue(value) {
        if (this.multiple) {
          return value.map(val => this.getSelectedOption(val)?.[this.optionLabel])
              .filter(v => v)
              .join(', ') || '-';
        } else {
          return this.getSelectedOption(value)?.[this.optionLabel] ?? '-';
        }
      },

      getSelectedOption(value) {
        if (value == null) {
          return this.options.find(option => option[this.optionKey] == null);
        } else if (typeof value === 'object') {
          return value;
        } else {
          return this.options.find(option => option[this.optionKey] === value);
        }
      },
    },
  };
</script>
