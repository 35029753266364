<template>
  <Datepicker
    ref="datepicker"
    :modelValue="value"
    @update:modelValue="updateValue"
    @cleared="onCleared"
    @closed="onClose"
    @open="onOpen"
    @internalModelChange="changeValue"
    @updateMonthYear="(val) => $emit('updateMonthYear', val)"
    autocomplete="off"
    calendarCellClassName="dp-custom-cell"
    :enableTimePicker="false"
    :menuClassName="`dp-custom-menu dp-custom-menu-${position}`"
    :month-year-component="monthYearPicker"
    hide-offset-dates
    v-bind="{
      name,
      autoApply,
      clearable,
      closeOnAutoApply,
      disabled,
      disabledDates,
      previewFormat,
      inputClassName,
      locale,
      minDate,
      maxDate,
      multiCalendars,
      placeholder,
      position,
      range,
      startDate,
      allowedDates,
      monthNameFormat,
    }"
  >
    <template v-slot:dp-input>
      <!-- @slot This slot replaces the input field. -->
      <slot name="input" v-bind="{ value }">
        <text-input
          :value="datePreview"
          :name="name"
          :inputClass="[inputClassName, isOpen ? 'form-input--focus' : null]"
          :placeholder="placeholder"
          autocomplete="off"
          :clearable="clearable"
          @clear="clearValue"
        >
          <template v-if="!clearable || !value" v-slot:trailing-icon>
            <icon class="w-4 h-4 text-graphite-800" name="calendar2" />
          </template>
        </text-input>
      </slot>
    </template>
    <template v-slot:action-select>
      <div class="text-sm">
        <button class="mr-4" type="button" @click="cancel">cancel</button>
        <button class="text-active-500" type="button" @click="applyValue">
          apply
        </button>
      </div>
    </template>
    <template v-slot:action-preview="props">
      <!-- @slot This slot replaces the date preview section in the action row -->
      <slot name="action-preview" v-bind="props"></slot>
    </template>
    <template v-slot:clear-icon></template>
  </Datepicker>
</template>

<script>
  import Datepicker from "@vuepic/vue-datepicker";
  import moment from "moment";
  import TextInput from "@/components/ui/TextInput";
  import CustomMonthYearPicker from "@/components/ui/datepicker/CustomMonthYearPicker";
  import Icon from "@/components/ui/Icon";
  import CommunityTimezoneMixin from "@/mixins/CommunityTimezoneMixin";

  /*
   * @vue/compat causes some issues with fired events, e.g. @update:modelValue does not work properly.
   * TODO: update components when full vue3 migration is finished
   * */
  export default {
    components: { Datepicker, TextInput, Icon },
    mixins: [CommunityTimezoneMixin],
    props: {
      /**
       * Sets the input name attribute
       */
      name: {
        type: String,
        default: null,
      },
      /**
       * Sets value. For range: [startDate, endDate]
       */
      value: {
        type: String,
        default: null,
      },
      /**
       * If set to true, clicking on a date value will automatically select the value
       */
      autoApply: {
        type: Boolean,
        default: false,
      },
      /**
       * Add a clear icon to the input field
       */
      clearable: {
        type: Boolean,
        default: false,
      },
      /**
       * Clicking on a date value will automatically select the value and close the datepicker menu
       */
      closeOnAutoApply: {
        type: Boolean,
        default: false,
      },
      /**
       * Disables the input
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * Disable specific dates
       */
      disabledDates: {
        type: [Function, Array], //String[] | (date: Date) => boolean, //return true for a disabled date
      },
      /**
       * Format the value of the date(s) in the input field
       */
      format: {
        type: String,
        default: "MM/DD/YYYY",
      },
      /**
       * Format the value of the date(s) in the action row
       */
      previewFormat: {
        type: String,
        default: null,
      },
      /**
       * Add a custom class to the input field
       */
      inputClassName: {
        type: String,
        default: null,
      },
      /**
       * All dates before the given date will be disabled
       */
      minDate: {
        type: String,
        default: null,
      },
      /**
       * All dates after the given date will be disabled
       */
      maxDate: {
        type: String,
        default: null,
      },
      /**
       * Show multiple calendars side by side
       */
      multiCalendars: {
        type: Boolean,
        default: false,
      },
      /**
       * Input placeholder
       */
      placeholder: {
        type: String,
        default: "",
      },
      /**
       * Input placeholder
       */
      position: {
        type: String,
        default: "center",
        validator: function (value) {
          return ["left", "center", "right"].indexOf(value) !== -1;
        },
      },
      /**
       * Range picker mode
       */
      range: {
        type: Boolean,
        default: false,
      },
      /**
       * Open the datepicker to some preselected month and year
       */
      startDate: {
        type: String,
        default: null,
      },
      /**
       * Allow only specific dates
       */
      allowedDates: {
        type: Array,
        default: () => [],
      },
      /**
       * Month/Year custom component
       */
      monthYear: {
        type: Function,
      },
      /**
       * Set the month name format
       */
      monthNameFormat: {
        type: String,
        default: "short",
      },
      /**
       * If date should be displayed in community timezone
       */
      inCommunityTimezone: {
        type: Boolean,
        default: false,
      },
    },
    emits: [
      "apply",
      "change",
      "update",
      "clear",
      "close",
      "updateMonthYear",
      "open",
    ],
    data() {
      return {
        isOpen: false,
        innerValue: null,
      };
    },
    computed: {
      monthYearPicker() {
        if (this.monthYear) {
          return this.monthYear;
        }

        return CustomMonthYearPicker;
      },
      datePreview() {
        if (!this.value) {
          return null;
        }

        if (this.range) {
          const [startDate, endDate] = this.value;

          if (!startDate) {
            return null;
          }

          return `${this.getFormattedDate(startDate)} - ${
            endDate ? this.getFormattedDate(endDate) : ""
          }`;
        }

        return this.getFormattedDate(this.value);
      },
      locale() {
        return this.$i18n?.locale;
      },
    },
    methods: {
      formatDate(date) {
        return moment(date).format("LL");
      },
      getFormattedDate(date) {
        return (this.inCommunityTimezone ? this.parseDateTime(date) : moment(date)).format(this.format);
      },
      applyValue() {
        this.$refs.datepicker.selectDate();
        /**
         * Emitted when the value is applied
         */
        this.$emit("apply", this.innerValue);
      },
      cancel() {
        this.$refs.datepicker.closeMenu();
      },
      changeValue(value) {
        if (!value) {
          return;
        }
        this.innerValue = value;
        /**
         * Emitted when the value is changed
         */
        this.$emit("change", value);
      },
      clearValue() {
        this.$refs.datepicker.clearValue();
      },
      onCleared() {
        /**
         * Emitted when the value is cleared on clear button
         */
        this.$emit("clear");
      },
      onOpen() {
        /**
         * Emitted when the datepicker menu is open
         */
        this.isOpen = true;
        this.$emit("open");
      },
      onClose() {
        /**
         * Emitted when the datepicker menu is closed
         */
        this.isOpen = false;
        this.$emit("close");
      },
      updateValue(value) {
        /**
         * Emitted when the value is selected
         */
        this.$emit("update", value);
      },
    },
  };
</script>
<style>
  .dp-custom-menu {
    @apply text-2sm;
  }
  .dp-custom-menu.dp-custom-menu-left .dp__arrow_top {
    left: 2rem;
  }
  .dp-custom-menu.dp-custom-menu-right .dp__arrow_top {
    left: initial;
    right: 2rem;
  }
  .dp-custom-cell.dp__cell_disabled:not(.dp__cell_offset) {
    @apply bg-graphite-200 text-graphite-900;
  }

  .dp__calendar_row {
    @apply m-0;
  }

  .dp__date_hover:hover {
    @apply bg-active-100;
  }

  .dp__calendar_header_separator {
    @apply hidden;
  }

  .dp__calendar_header_item {
    @apply text-2xs font-inter font-semibold text-black h-6 w-8;
  }

  .dp__calendar_wrap {
    @apply mx-4 mb-4;
  }

  .dp__cell_inner {
    @apply border-0 rounded-none h-6 w-8 text-black text-2xs font-inter;
  }

  .dp__active_date {
    @apply rounded-sm bg-active-500 text-white;
  }
</style>
