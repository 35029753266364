<template>
  <FinalForm
    ref="form"
    :initial-values="initialValues"
    @change.self="handleChangeFilters"
  >
    <div class="mb-6">
      <div class="flex justify-between items-baseline">
        <span class="font-frank font-400 mr-3">viewing:</span>
        <div class="flex-grow mr-4">
          <CollapsibleTags
            :items="filterTagsToDisplay"
            @delete="
              (appliedFilter) =>
                clearFilterValue(appliedFilter.name, appliedFilter.value)
            "
            :readonly="readonly"
          />
        </div>
        <div class="flex items-center search-filter">
          <TextField
            name="searchQuery"
            placeholder="search"
            clearable
            :disabled="readonly"
          >
            <template v-slot:leading-icon>
              <Icon name="search2" class="w-4 h-4 text-graphite-800" />
            </template>
          </TextField>
          <FilterIcon
            v-if="!readonly"
            class="w-6 h-6 ml-3 cursor-pointer"
            :class="{ 'text-blue-700': opened }"
            @click="opened = !opened"
          />
        </div>
      </div>
      <div
        class="filters-form bg-blue-50 border border-blue-100 px-6 py-3 mt-6"
        :class="{ hidden: !opened }"
      >
        <div class="grid grid-cols-3 gap-6">
          <DateRangeInput
            class="filter"
            label="creation date"
            name="createdAt"
            :max-date="new Date().toISOString()"
            position="left"
            clear
          >
            <template v-slot:input="props">
              <text-input
                v-if="!props.startDate && !props.endDate"
                value="All"
              />
            </template>
          </DateRangeInput>
          <SelectInput
            class="filter"
            label="assigned to"
            name="assignees"
            :options="options.assignees"
            multiple
          />

          <SelectInput
            class="filter"
            label="status"
            name="statuses"
            :options="options.statuses"
          />

          <SelectInput
            class="filter"
            label="category"
            name="categories"
            :options="options.categories"
            multiple
          />

          <SelectInput
            class="filter"
            label="priority"
            name="priorities"
            :options="options.priorities"
            multiple
          />
        </div>
        <div class="flex my-4">
          <div class="flex-grow" />
          <button :disabled="!canReset" class="btn-primary" @click="resetForm">
            reset
          </button>
        </div>
      </div>
    </div>
  </FinalForm>
</template>

<script>
  import { FinalForm } from "vue-final-form";
  import { mapActions, mapGetters } from "vuex";
  import FilterIcon from "@/components/ui/icons/FilterIcon";
  import DateRangeInput from "@/components/form/DateRangeInput";
  import SelectInput from "@/components/form/SelectInput";
  import TextField from "@/components/form/TextField";
  import CommunityTimezoneMixin from "@/mixins/CommunityTimezoneMixin";
  import TextInput from "@/components/ui/TextInput";
  import Icon from "@/components/ui/Icon";
  import { isEqual, isNil, pick, pickBy, upperFirst } from "lodash-es";
  import CollapsibleTags from "@/components/ui/CollapsibleTags";
  import NotifyMixin from "@/mixins/NotifyMixin";

  export default {
    name: "FiltersBlock",

    components: {
      CollapsibleTags,
      TextField,
      SelectInput,
      FinalForm,
      DateRangeInput,
      FilterIcon,
      TextInput,
      Icon,
    },

    mixins: [CommunityTimezoneMixin, NotifyMixin],

    props: {
      readonly: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        opened: false,
        options: {
          assignees: [],
          statuses: [],
          priorities: [],
          categories: [],
        },

        initialValues: null,
        formValues: null,
        // needed to properly unselect "all" when one of all selected options unselected
        prevFormValues: null,
        filterTagsToDisplay: [],
      };
    },

    computed: {
      ...mapGetters({
        filterValues: "sreq/listFilterValues",
      }),

      canReset() {
        return this.filterTagsToDisplay.filter((f) => f.clearable).length > 0;
      },
    },

    methods: {
      ...mapActions({
        setFilterValues: "sreq/setListFilterValues",
      }),

      resetForm() {
        this.$refs.form.finalForm.reset({
          searchQuery: "",
          createdAt: null,
          assignees: [],
          statuses: null,
          categories: [],
          priorities: [],
        });
      },

      clearFilterValue(name, itemValue) {
        const { finalForm, formState } = this.$refs.form;

        const oldValue = formState.values[name];

        const newValue = Array.isArray(oldValue)
          ? oldValue.filter((value) => value !== itemValue)
          : null;

        finalForm.change(name, newValue);
      },

      /**
       * Returns form field value, not filter value
       *
       * @param inputValue
       * @param fieldName
       * @returns {null|*}
       */
      getChangedComplexFieldValue(inputValue, fieldName) {
        if (inputValue?.length === 0) {
          // return null instead of []. Just to not pass a bunch of values when it's not necessary
          return null;
        } else {
          const addedDiff = (inputValue || []).filter(
            (value) => !(this.formValues[fieldName] || []).includes(value)
          );
          // need this check to ignore just made mass updates
          if (addedDiff.length === 1) {
            // user checked option "all" OR all items except "all" selected
            if (
              addedDiff[0] === "ALL" ||
              inputValue.length === this.options[fieldName].length - 1
            ) {
              return this.options[fieldName].map(({ key }) => key);
            }
          }

          const removedDiff = (this.formValues[fieldName] || []).filter(
            (value) => !(inputValue || []).includes(value)
          );
          // need this check to ignore just made mass updates
          if (removedDiff.length === 1) {
            // user unchecked "all" option
            if (removedDiff[0] === "ALL") {
              // uncheck everything
              return null;
            }
            // "all" was checked and user unchecked something
            else if (inputValue.includes("ALL")) {
              // uncheck "all" option
              return inputValue.filter((val) => val !== "ALL");
            }
          }
        }

        return inputValue;
      },

      handleChangeFilters(formState) {
        const pickFunc = (a) => !isNil(a);
        if (
          isEqual(
            pickBy(formState.values, pickFunc),
            pickBy(this.prevFormValues, pickFunc)
          )
        ) {
          return;
        }

        const newFormValueToSet = { ...formState.values };

        if (this.$refs.form) {
          newFormValueToSet.assignees = this.getChangedComplexFieldValue(
            formState.values.assignees,
            "assignees"
          );
          newFormValueToSet.categories = this.getChangedComplexFieldValue(
            formState.values.categories,
            "categories"
          );
          newFormValueToSet.priorities = this.getChangedComplexFieldValue(
            formState.values.priorities,
            "priorities"
          );

          this.$refs.form.finalForm.batch(() => {
            for (const [key, value] of Object.entries(newFormValueToSet)) {
              this.$refs.form.finalForm.change(key, value);
            }
          });

          // add/remove tags for filters with multiple selection
          const complexValues = pick(newFormValueToSet, [
            "assignees",
            "categories",
            "priorities",
          ]);
          for (const [fieldName, newFieldValue] of Object.entries(
            complexValues
          )) {
            const addedFieldDiff = (newFieldValue || [])
              .filter(
                (value) =>
                  !(this.prevFormValues?.[fieldName] || []).includes(value)
              )
              .filter((value) => value !== "ALL"); // we don't need "all" tags
            for (const addedValue of addedFieldDiff) {
              this.filterTagsToDisplay.unshift({
                name: fieldName,
                value: addedValue,
                text: this.options[fieldName].find((o) => o.key === addedValue)
                  ?.value,
                clearable: true,
              });
            }

            const removedFieldDiff = (
              this.prevFormValues?.[fieldName] || []
            ).filter((value) => !(newFieldValue || []).includes(value));
            for (const removedValue of removedFieldDiff) {
              this.filterTagsToDisplay = this.filterTagsToDisplay.filter(
                ({ value }) => value !== removedValue
              );
            }
          }

          // add/remove date filter tag
          if (newFormValueToSet?.createdAt && !this.prevFormValues?.createdAt) {
            this.filterTagsToDisplay.unshift({
              name: "createdAt",
              value: newFormValueToSet.createdAt,
              text: `${this.formatDateTime(
                newFormValueToSet.createdAt.startDate,
                "L",
                true
              )} - ${this.formatDateTime(
                newFormValueToSet.createdAt.endDate,
                "L",
                true
              )}`,
              clearable: true,
            });
          } else if (
            !newFormValueToSet?.createdAt &&
            this.prevFormValues?.createdAt
          ) {
            this.filterTagsToDisplay = this.filterTagsToDisplay.filter(
              ({ name }) => name !== "createdAt"
            );
          }

          // add/remove status filter tag
          const tagsWithoutStatuses = this.filterTagsToDisplay.filter(
            ({ name }) => name !== "statuses"
          );
          if (newFormValueToSet?.statuses) {
            this.filterTagsToDisplay =
              newFormValueToSet.statuses !== "ALL"
                ? [
                    {
                      name: "statuses",
                      value: newFormValueToSet.statuses,
                      text: this.options.statuses.find(
                        ({ key }) => key === newFormValueToSet.statuses
                      )?.value,
                      clearable: true,
                    },
                    ...tagsWithoutStatuses,
                  ]
                : tagsWithoutStatuses;
          } else if (this.prevFormValues?.statuses) {
            this.filterTagsToDisplay = tagsWithoutStatuses;
          }

          this.prevFormValues = newFormValueToSet;
        }

        this.formValues = newFormValueToSet;

        this.setFilterValues({
          ...this.formValues,
          assignees:
            this.formValues.assignees?.length === this.options.assignees.length
              ? null
              : this.formValues.assignees,
          statuses:
            this.formValues.statuses === "ALL"
              ? null
              : this.formValues.statuses,
          categories:
            this.formValues.categories?.length ===
            this.options.categories.length
              ? null
              : this.formValues.categories,
          priorities:
            this.formValues.priorities?.length ===
            this.options.priorities.length
              ? null
              : this.formValues.priorities,
        });
      },
    },

    created() {
      this.prevFormValues =
        this.formValues =
        this.initialValues =
          this.filterValues;
    },

    mounted() {
      Promise.all([
        this.$sreqDataProvider
          .getFilterOptions("serviceRequests")
          .then(({ statusFilterOptions, categoryFilterOptions }) => {
            const groups = statusFilterOptions.reduce(
              (acc, item) => {
                acc[item.multiselectable ? "realStatuses" : "aliases"].push({
                  key: item.filter,
                  value: upperFirst(item.label),
                });
                return acc;
              },
              { aliases: [], realStatuses: [] }
            );
            this.options.statuses = [
              ...groups.aliases,
              { $isSeparator: true },
              ...groups.realStatuses,
            ];

            this.options.categories = [
              { key: "ALL", value: "All" },
              { key: "NO_VALUE", value: "No category" },
              ...categoryFilterOptions.map((name) => ({
                key: name,
                value: upperFirst(name),
              })),
            ];
          }),
        this.$sreqDataProvider.getList("assignees").then((assignees) => {
          this.options.assignees = [
            { key: "ALL", value: "All" },
            { key: "NO_VALUE", value: "No assignee" },
            ...assignees
              .map((item) => ({
                ...item,
                firstName: item.firstName,
                lastName: item.lastName,
              }))
              .sort(
                (a, b) =>
                  a.lastName.localeCompare(b.lastName) * 10 +
                  a.firstName.localeCompare(b.firstName)
              )
              .map((item) => ({
                key: item.id,
                value: `${item.firstName} ${item.lastName}`,
              })),
          ];
        }),
        this.$sreqDataProvider.getList("priorities").then((priorities) => {
          this.options.priorities = [
            { key: "ALL", value: "All" },
            ...priorities.map((p) => ({
              key: p.id,
              value: upperFirst(p.name),
            })),
          ];
        }),
      ])
        .then(() => {
          this.filterTagsToDisplay = [
            this.formValues.createdAt && {
              name: "createdAt",
              value: this.formValues.createdAt,
              text: `${this.formatDateTime(
                this.formValues.createdAt.startDate,
                "L",
                true
              )} - ${this.formatDateTime(
                this.formValues.createdAt.endDate,
                "L",
                true
              )}`,
              clearable: true,
            },
            this.formValues.statuses && {
              name: "statuses",
              value: this.formValues.statuses,
              text: this.options.statuses.find(
                ({ key }) => key === this.formValues.statuses
              )?.value,
              clearable: true,
            },
            ...Object.entries(
              pick(this.formValues, ["assignees", "categories", "priorities"])
            ).reduce((acc, [fieldName, values]) => {
              return !values?.length
                ? acc
                : [
                    ...values.map((val) => ({
                      name: fieldName,
                      value: val,
                      text: this.options[fieldName].find((o) => o.key === val)
                        ?.value,
                      clearable: true,
                    })),
                    ...acc,
                  ];
            }, []),
          ].filter((v) => v);
        })
        .catch((error) => {
          this.notifyError(error.message);
        });
    },
  };
</script>

<style scoped>
  .filters-form {
    position: relative;
  }

  .filters-form:before,
  .filters-form:after {
    position: absolute;
    content: "";
    bottom: 100%;
    border-color: transparent;
  }

  .filters-form:before {
    right: 3px;
    border-left-width: 8px;
    border-right-width: 8px;
    border-bottom: 1rem solid #eff8fe;
  }

  .filters-form:after {
    right: 4px;
    border-left-width: 7px;
    border-right-width: 7px;
    border-bottom: 14px solid #f7fcff;
  }

  .filter {
    margin: 0;
  }

  .filter:deep(label) {
    @apply text-xs;
  }

  .filter:deep(.checkbox-label) {
    margin-left: 18px;
  }

  .filter:deep(input[type="checkbox"]) {
    width: 12px;
    height: 12px;
    margin-left: -18px;
    vertical-align: -2px;
  }

  .search-filter:deep(.form-col) {
    @apply mx-0;
  }

  .search-filter:deep(.form-input) {
    min-width: 280px;
  }
</style>
